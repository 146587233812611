// Blog archive/listing page
.blog-archive {
  h1 {
    text-align: center;
  }
  .blog--item {
    margin-bottom: 3rem;
    border: 1px solid $brandblue;
    border-radius: 5px;
    &-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      padding: 0 1rem;
    }
    &-featuredimg {
      border-radius: 4px 4px 0 0;
      width: 100%;
    }
    .blog-list-meta {
      margin-bottom: 2rem;
      display: block;
      padding: 0 1rem;
    }
    .full {
      padding: 0 1rem;
      margin-bottom: 2rem;
      .read-more-rarr {
        color: $brand-main;
      }
    }
  }
}
.blog-index {
  min-height: 20vh;
  padding: 5rem 0;
  margin-bottom: 4rem;
  h1 {
    color: $brandblue;
  }
}

.blog-list-exccerpt {
  color: black;
}
.pagination {
  list-style: none;
  margin: 2em auto 0;
  width: 100%;
  text-align: center;
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
  }
  a {
    padding: 3px 10px;
    border: 2px solid $brand-main;
    border-radius: 3px;
    margin: 0 5px;
    color: $brand-main-complement;
    display: block;
    &:hover {
      background: rgba($brand-main, 0.5);
      color: $brand-main-complement;
    }
  }
  .current a {
    background: $brand-main;
    color: white;
  }
}

// Single blog page
.single-blog-item {
  padding: 4em 0;
}

.content-area .container {
  // max-width: $containedWidth;
  iframe {
    width: 100%;
    max-width: 100%;
  }
}
.wp-caption {
  max-width: 100%;
}
.content {
  width: 100%;
  max-width: 100%;
  p {
    max-width: $tablet-width;
    margin: 1em auto;
    font-weight: 200;
    @include lessThanTablet() {
      width: 100%;
      padding: 0 1em;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: $tablet-width;
    margin: 1em auto;
    @include lessThanTablet() {
      width: 100%;
      padding: 0 1rem;
    }
  }
  h1 {
    font-size: 2.3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.35rem;
  }
  h6 {
    font-size: 1rem;
  }
  blockquote {
    max-width: $tablet-width + 100;
    margin: 1em auto;
    @include lessThanTablet() {
      width: 100%;
      padding: 0 1em;
    }
  }
  img {
    margin: 3em auto;
    display: block;
  }
  hr {
    border: none;
    max-width: 480px;
    margin: 4em auto;
    height: 1px;
    background: $brand-secondary;
  }
  .wp-video {
    margin: auto;
    box-shadow: 0 18px 42px 1px rgba(136, 136, 136, 0.65);
    box-shadow: 0 18px 42px 1px #888888a6;
    border-radius: 30px;
    margin: 5em auto 7em;
  }
  ul {
    list-style: disc;
    margin-left: 0;
    max-width: 768px;
    margin: 1em auto;
    font-weight: 200;
    padding-left: 1rem;
  }
  ul,
  ol {
    li {
      margin-bottom: 0.3em;
      padding: 0.5em 0;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  a.inline-btn {
    background: $brand-secondary;
    text-decoration: none;
    padding: 0.3em 0.6em;
    display: inline-block;
    margin-bottom: 0.4em;
    color: $offWhite;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.23s ease;
    &:hover {
      border-radius: 0;
    }
  }
}

figure.wp-block-image {
  margin: 0;
}

.simpul-hero.single-blog {
  padding: 10rem 0 5rem;
  height: auto;
  min-height: 85vh;
  display: flex;
  align-items: flex-end;
  .container {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1024px) {
    .hero-content {
      width: 60%;
    }
  }
  h1 {
    color: $brandblue;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 4rem;
    @media screen and (max-width: 1440px) {
      font-size: 3.5rem;
    }
  }
  .btn {
    padding: 0.5rem 1rem;
    font-weight: normal;
    border-radius: 20px;
    font-size: 1rem;
    margin-right: 1rem;
    &:hover {
      background-color: $brandblue;
      color: white;
    }
  }
}
.single-post {
  .full-side-bg {
    width: auto;
    height: 70%;
    top: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 5px;
    right: 2vw;
    bottom: 2vw;
    max-height: 450px;
    @media screen and (max-width: 1200px) {
      max-height: 350px;
    }
  }
}
.dm-cat-filter-area h3 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  display: block;
}

.dm-cat-filter-area {
}

#contenttype,
#cat {
  border: none;
  border-radius: 1px;
  background: transparent;
  color: #274190;
  min-width: 320px;
  padding: 1rem 1rem 1rem 0;
  border-bottom: 2px solid #274190;
}

.dm-cat-filter-area select {
  appearance: none;
  .select2 {
    @include lessThanTablet() {
      display: block;
    }
  }
}

.dm-cat-filter-area .filter-select {
  display: inline-block;
  position: relative;
  @include lessThanTablet() {
    margin-bottom: 2rem;
  }
}
.first-filter-select {
  margin-right: 2rem;
  margin-left: 9px;
  @include lessThanTablet() {
    margin-left: 0;
  }
}

.dm-cat-filter-area .filter-select:after {
  content: "▼";
  right: 10px;
  position: absolute;
  top: 31%;
  pointer-events: none;
}

@media screen and (max-width: 1024px) {
  #contenttype,
  #cat {
    margin-left: 0;
    margin-top: 1rem;
  }
}

#toc {
  margin-top: 2rem;
  align-self: flex-start;
  /*               top: 7vh; */
  position: relative;
  /* max-height: 90vh; */
  /* overflow: auto; */
  padding: 3rem 0;
  /*               border-radius: 10px; */
  @include lessThanTablet() {
    display: none;
  }
}

#toc a {
  margin-bottom: 0.85rem;
  display: block;
  text-decoration: none;
  font-size: 1rem;
}

#toc .h2 a {
  text-indent: -26px;
  padding-left: 26px;
}

#toc .h1 {
  margin-left: 0rem;
}

#toc .h2 {
  margin-left: 1rem;
}

#toc .h3 {
  margin-left: 2em;
}

#toc .h4,
#toc .h5,
#toc .h6 {
  margin-left: 3em;
}

#content {
  padding-top: 3rem;
}

div#toc:before {
  content: "Contents";
  width: calc(100% - 2rem);
  display: block;
  position: absolute;
  top: 0.5rem;
  box-sizing: border-box;
  font-weight: bold;
  margin-left: 1rem;
}

@media screen and (max-width: 1024px) {
  #toc {
    /*                 position: static; */
    width: 100%;
  }
}

.modern-footnotes-list {
  display: none;
}

.modern-footnotes-list.open {
  display: block;
}

.modern-footnotes-list-heading {
  cursor: pointer;
  border-top: 1px solid #e2e2e2;
  padding-top: 1.5rem;
  font-size: 1.4rem !important;
}

.modern-footnotes-list-heading:after {
  content: "▼";
  margin-left: 0.8rem;
  font-size: 1rem;
  transition: all 0.2s ease;
  display: inline-block;
}

.modern-footnotes-list-heading:has(+ .open):after {
  transform: rotate(180deg);
}
