footer.site-footer {
  background-color: $brand-main;
  padding: 6rem 0;
  position: relative;
  color: white;
  .copyright {
    width: 100%;
    font-size: 0.8em;
    margin-top: 4rem;
    a {
      color: white;
      margin: 13px 0;
      display: inline-block;
    }
  }
  #menu-footer-menu {
    padding-left: 0;
    a {
      color: white;
      margin-bottom: 0.3rem;
      display: inline-block;
    }
  }
}
.social-icons {
  width: 100%;
  position: relative;
  @include lessThanDesktop() {
    margin: 2em 0;
  }
  img {
    max-width: 30px;
    margin-right: 0.5em;
  }
  a {
    color: $offWhite;
  }
  .social-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @include lessThanDesktop() {
      justify-content: center;
    }
  }
}
.sd-social-icon .sd-content ul li.share-twitter a:before {
  content: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='white' d='M-0.1,0l19.3,28.1L0.3,50h4.2L21,30.8L34.2,50H50L29.9,20.6L47.6,0h-4.2L28,17.9L15.7,0L-0.1,0z M4.9,2.6h9.4L45,47.4h-9.4 L4.9,2.6z'/%3E%3C/svg%3E%0A") !important;
  width: 12px;
  top: -3px !important;
}
.sd-social-icon .sd-content ul li.share-facebook a:before {
  content: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='white' d='M32.6,9.8H38c0.6,0,1.1-0.5,1.1-1.1V1.4c0-0.6-0.4-1-1-1.1C36.4,0.2,33,0,30.6,0c-6.7,0-11,4-11,11.3v7.2H12 c-0.6,0-1.1,0.5-1.1,1.1v7.6c0,0.6,0.5,1.1,1.1,1.1h7.6v20.7c0,0.6,0.5,1.1,1.1,1.1h7.6c0.6,0,1.1-0.5,1.1-1.1V28.3h7.9 c0.6,0,1-0.4,1.1-1l0.8-7.6c0.1-0.6-0.4-1.2-1.1-1.2h-8.7V13C29.3,11.2,30.8,9.8,32.6,9.8z'/%3E%3C/svg%3E%0A") !important;
  width: 14px;
  top: -2px !important;
}
.sd-social-icon .sd-content ul li.share-linkedin a:before {
  content: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='White' d='M7.1,0c-3.3,0-6,2.7-6,6s2.7,6.1,6,6.1s6-2.7,6-6.1C13.1,2.7,10.5,0,7.1,0z M36.4,15.9c-5,0-7.9,2.6-9.3,5.3H27v-4.6H17V50 h10.4V33.5c0-4.4,0.3-8.6,5.7-8.6c5.3,0,5.4,5,5.4,8.8V50h10.3h0V31.7C48.9,22.7,46.9,15.9,36.4,15.9z M2,16.6V50h10.4V16.6H2z'/%3E%3C/svg%3E%0A") !important;
  width: 14px;
  top: -3px !important;
}
.sd-content a {
  width: 32px !important;
  height: 32px !important;
  text-align: center !important;
}
footer .social-icons {
  display: flex;
}
footer .social-icons a {
  display: block;
  margin: 0 15px 0 0;
  border-radius: 50px;
  border: 1px solid white;
  display: inline-block;
  height: 38px;
  width: 38px;
  vertical-align: middle;
}
footer .social-icons a svg {
  max-width: 50%;
  display: block;
  margin: auto;
  height: 100%;
}

footer .social-icons a:hover {
  border-color: $brandorange;
  path {
    fill: $brandorange;
  }
}

.footer-tel,
.footer-email {
  color: $offWhite;
  margin: 1em auto;
  display: inline-block;
}
.footer-tel {
  margin-bottom: 0.3rem;
}
.footer-email {
  margin-top: 0;
}
