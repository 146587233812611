.contact-page {
  .widesection {
    padding: 6rem 0;
  }
  .contactform {
    h3 {
      font-weight: 300;
      font-size: 2.5rem;
      margin-top: 0;
      padding: 0 0.5rem;
    }
  }
  .address {
    font-size: 1.75rem;
    @include lessThanTablet() {
      font-size: 1.3rem;
    }
  }
  .gmapssection {
    p {
      margin: 0;
    }
    iframe {
      width: 100%;
      min-height: 400px;
      min-height: 66vh;
      display: block;
      @include desktop() {
        min-height: 50vh;
      }
    }
  }
}
.wpcf7-form {
  // wpcf7 adds these P tags, just use it as a wrapper
  > p {
    position: relative;
    label {
      position: absolute;
      top: 2.7em;
      transform: translateY(-50%);
      pointer-events: none;
      transition: top 0.2s;
    }
  }
  input.wpcf7-text {
    margin-top: 1rem;
  }
  textarea.wpcf7-textarea {
    margin-top: 1rem;
  }
  // fancy label function
  label.has-value {
    top: 0;
    font-size: 1rem;
  }
}
