.careers-blocks {
  padding: 8rem 0 4rem;
}
.careers-title {
  text-align: center;
  margin-bottom: 3rem !important;
}
.gradescheme-page-first-content,
.workingat-page-first-content {
  max-width: 800px;
  // margin: auto;
  padding: 4rem 1rem 4rem;
  // text-align: center;
  font-size: 1.5rem;
  .container {
    width: 100%;
  }
  h1 {
    margin-top: 0;
  }
}
.workingat-page-title {
  font-size: 2rem;
  margin-top: 3rem;
}
.our-perks {
  // text-align: center;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  .container {
    width: 100%;
    padding: 0 1rem;
  }
  ul {
    list-style-type: disc;
    // max-width: 40%;
    columns: 2;

    display: inline-block;
    text-align: left;
    vertical-align: top;
    padding-left: 1rem;
    width: 100%;
    li {
      margin-right: 10px;
      margin-bottom: 1rem;
    }
  }
  .our-perks-list {
    justify-content: space-between;
  }
}
.our-perks-title {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.our-perks-description {
  font-weight: bold;
  font-size: 2rem;
  max-width: 800px;
  // margin: auto;
  // padding: 0 1rem;
  p {
    line-height: 1.3;
  }
}
.our-commitment {
  color: white;
}

.about-the-role,
.our-commitment {
  padding: 4rem 0;
  &-title {
    font-size: 2rem;
    max-width: 450px;
    margin: auto;
    margin-bottom: 2rem;
  }
  &-description {
    font-size: 2rem;
    max-width: 950px;
    margin: auto;
    margin-bottom: 2rem;
  }
  &-title,
  &-description {
    text-align: center;
  }
  &-content {
    max-width: $containedWidth;
    margin: auto;

    margin-top: 4rem;
    margin-bottom: 4rem;
    p:first-of-type {
      margin-top: 0;
    }
  }
}
.our-commitment {
  &-content {
    column-count: 2;
  }
}
.about-the-role {
  .container {
    max-width: 1024px;
  }
  &-content {
    text-align: left;
    padding-right: 4rem !important;
    @include lessThanDesktop() {
      padding: 0.2rem !important;
    }
  }
}
