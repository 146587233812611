html {
  width: 100%;
}
body {
  color: black;
  position: relative;
  width: 100%;
}

#navburger {
  width: 25px;
  height: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  text-align: left;
  margin-left: auto;
  display: none;
  @include lessThanDesktop() {
    display: block;
  }
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  &.open {
    position: relative;
    z-index: 1000;
  }
}

#navburger span:nth-child(1) {
  top: 0px;
}

#navburger span:nth-child(2),
#navburger span:nth-child(3) {
  top: 10px;
}

#navburger span:nth-child(4) {
  top: 20px;
}

#navburger.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#navburger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#navburger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#navburger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

header.site-header {
  padding: 1rem 0;
  width: 100%;
  z-index: 100;
  background: $brand-main;
  .flex-wrap {
    align-items: center;
  }
  .logo {
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    img,
    svg {
      width: auto;
      transition: all 0.2s ease;
      display: block;
      height: 50px;
    }
  }
  .nav {
    margin-left: auto;
    @include lessThanDesktop() {
      display: none;

      &.open {
        display: block;
        position: absolute;
        .menu-main-menu-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          height: 100vh;
          background: $brand-main;
          z-index: 100;
          font-size: 1em;
          padding-top: 50px;
          transition: all 0.3s ease;
          overflow: scroll;
          li {
            display: block;
            width: 100%;
            text-align: center;
            margin: 1em auto 3rem;
            &:last-child a {
              padding-right: 20px;
            }
            a {
              padding: 20px;
            }
          }
        }
        .menu {
          display: block;
          position: absolute;
          z-index: 100;
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }
  .menu {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0;

    padding: 0;
    li:last-of-type a {
      background: $brandpink;
      border-radius: 13px;
      padding: 0.5rem 1rem;
    }

    a {
      color: white;
    }
    @include lessThanDesktop() {
      display: none;
    }
    @include desktop() {
      .menu-main-menu-container {
        display: block !important;
      }
    }
    .current-menu-item {
    }
    a {
      padding: 0 20px;
      &:hover {
        text-decoration: none;
        // border-bottom: 2px solid;
        // padding: 0;
        // margin: 0 20px;
      }
    }
    @include lessThanTablet() {
      justify-content: center;
    }
  }
}

.simpul-hero {
  background-color: $brand-main;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 10rem 0 10rem;
  position: relative;
  min-height: 33vw;
  &.hero-shape {
    background-size: auto 100%;
    background-position: 100%;
    background-color: transparent;
  }
  @include lessThanDesktop() {
    padding: 6rem 0;
  }
  .hero-content {
    display: inline-block;
    .hero-title {
      color: $brand-main;
      font-weight: $contentFontsWeight;
      line-height: 1.1;
      font-size: 4em;
      margin: 0;
      @include lessThanTablet() {
        font-size: 3rem;
      }
    }
    @include mobile() {
      padding: 0.5em;
    }
  }
  h1 {
    margin-bottom: 0.5em;
  }
  h2 {
    margin-top: 0;
    color: $brand-main-complement;
  }
  p {
    color: white;
  }
  h1,
  h2 {
    color: white;
  }
  h3 {
    font-size: 2em;
  }
  h4 {
    color: white;
    font-size: 2em;
  }
}

#navburger {
  padding: 20px;
  height: auto;
  width: auto;
}
#navburger span {
  width: 25px;
  left: 8px;
}
#navburger span:first-child {
  top: 7px;
}

.categories-list {
  max-width: 800px;
  margin: 9px auto 2rem !important;
}
.categories-list a {
  display: inline-block;
  padding-bottom: 2rem;
}
#navburger span:nth-child(2),
#navburger span:nth-child(3) {
  top: 16px;
}
#navburger span:nth-child(4) {
  top: 25px;
}

header.site-header .menu a:before {
  position: absolute;
  bottom: -5px;
  width: 0;
  transition: all 0.2s ease;
  height: 1px;
  content: "";
  left: 20px;
  background: $brandpink;
}
header.site-header .menu a {
  position: relative;
  &:hover {
    color: $brandpink;
  }
  &:hover:before {
    width: calc(100% - 40px);
  }
}
header.site-header .menu > li:last-of-type a:before {
  display: none;
}

header.site-header .menu > li:last-of-type a:hover {
  color: white !important;
  background-color: $brandorange;
}
.site-header li:nth-of-type(1) {
  animation-delay: 0.5s;
}
.site-header li:nth-of-type(2) {
  animation-delay: 0.4s;
}
.site-header li:nth-of-type(3) {
  animation-delay: 0.3s;
}
.site-header li:nth-of-type(4) {
  animation-delay: 0.2s;
}
.site-header li:nth-of-type(5) {
  animation-delay: 0.1s;
}
.site-header li:nth-of-type(6) {
  animation-delay: 0s;
}

.site-scrolled {
  &.site-header {
    padding: 1rem 0 0.5rem;
  }
  svg {
    height: 38px !important;
    path {
      display: none;
    }
  }
  .demandmore-logo-d {
    display: block;
  }
}

.hero-subtitle {
  color: white;
  margin-top: 1.3rem;
  max-width: 480px;
  font-size: 1.5rem;
  line-height: 1.3;
}
