input[type="checkbox"] {
  width: auto;
}
.btn,
button,
.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 3px solid $brand-main;
  border-radius: 3px;
  padding: 10px 20px;
  color: $brand-main;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 1em;
  &:hover {
    background-color: $brandorange;
    color: $brand-main;
  }
}
mark {
  background: rgba(204, 146, 79, 0.129);
  background: #cc924f21;
  padding: 2px 5px;
  border-radius: 3px;
  margin: 0 2px;
}
p code {
  background: $offWhite;
  display: inline-block;
  border: 1px dashed $offWhite;
  border-radius: 6px;
  padding: 0 10px;
}

.read-more-rarr {
  margin-bottom: 1rem;
  vertical-align: middle;
  color: white;
  .rarr-icon {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50px;
    width: 2.2rem;
    margin-right: 0.3rem;
    height: 2.2rem;
    color: white;
    background-color: $brandpink;
    background-image: url(img/right-chev.png);
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: center;
  }
}

.what-we-do {
  background: $brandpink;
  color: white;
  padding: 6rem 0;
  &-title {
    // text-align: center;
  }
  .single-what-we-do {
    padding: 2rem 0 3rem;
    @include lessThanTablet() {
      padding: 1rem;
    }
    &-title {
      margin-top: 0;
      margin-bottom: 0.3rem;
      font-size: 2.5rem;
      @include lessThanTablet() {
        font-size: 2rem;
      }
    }
    img {
      margin: auto;
      display: block;
    }
  }
}
.what-we-do-img {
  max-width: 150px;
  max-height: 150px;
}
.tech-description {
  line-height: 1.4;
}

.case-studies-trio {
  text-align: center;
  background: white;
  padding: 4rem 0;
  text-decoration: none;
  * {
    text-decoration: none;
  }
}
.case-studies-inc {
  margin-bottom: 3rem;
  .read-more-rarr {
    color: #000;
    .rarr-icon {
      background-color: $brandblue;
    }
  }
}
.case-study-trio-single-micro {
  font-size: 1rem;
  margin-top: auto;
  margin-bottom: 0.7rem;
  color: #969696;
}
.case-study-trio-single-title {
  // margin-bottom: 3rem;
  // color: #000;
  font-size: 2rem;
}
.case-study-trio-single {
  padding: 2rem;
  min-height: 450px;
  background: #e3e3e3;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.comments {
  margin: 2rem auto;
  label {
    padding: 0;
  }
  textarea,
  input {
    width: 100%;
    border: 1px solid $brandblue;
    border-radius: 4px;
    &:hover {
      border: 1px solid $brandpink;
    }
  }
  #reply-title {
    font-size: 1rem;
    margin: 0;
  }
  .comment-form-cookies-consent {
    display: flex;
    align-items: center;
  }
  #wp-comment-cookies-consent {
    width: auto;
    margin-right: 1rem;
  }
}
.twothird.comments {
  margin-bottom: 3rem !important;
}
.comment-form-comment textarea {
  color: #274190;
}
.commentlist {
  list-style: none;
  padding-left: 0;
}
.commentlist .comment {
  border-left: 1px solid #274190;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
.commentlist .comment img {
  vertical-align: middle;
}
.comment-author {
  margin-bottom: 1rem;
}
.comment-body {
  position: relative;
}
.comment-body .reply {
  position: absolute;
  top: 1rem;
  right: 0;
}
.comment-body .reply a {
  background: #274190;
  color: white;
  border-radius: 3px;
  padding: 0.33rem 1rem;
  font-size: 1rem;
  text-decoration: none;
}
.comments #reply-title {
  margin-top: 2rem;
  display: block;
}
.comment-form-comment label {
  font-weight: bold;
}
.comment-form-comment textarea {
  border-width: 2px;
  border-color: transparent transparent #5367a4;
  background: transparent;
  border-radius: 0;
  padding: 1rem 0.5rem;
  color: #fff;
}
.comments input,
.comments textarea {
  border-width: 0 0 2px 0;
  border-radius: 0;
}
h3#comments {
  font-size: 1.5rem;
}
.author-img {
  // text-align: center;
  img {
    width: 150px;
    padding: 0;
    border-radius: 100px;
    @include lessThanTablet() {
      width: 100px;
    }
  }
}
.author-description {
  text-align: left;
  margin-left: 2rem;
  @include lessThanTablet() {
    margin-left: 0;
    margin-top: 9px;
  }
}
.author-description-bio {
  line-height: 1.6;
}

.twothird.comments {
  max-width: 768px;
}
.author-area {
  border: none;
  padding: 1rem 0;
  width: 100%;
  color: #fff;
  margin-bottom: 2rem !important;
  @include lessThanTablet() {
    margin-bottom: 1rem !important;
  }
  .flex-wrap {
    align-items: center;
  }
  .author-name {
    color: white;
  }
  .author-title {
    font-weight: normal;
    font-size: 1.2rem;
  }
  svg {
    width: 100%;
    // vertical-align: middle;
  }
  .author-social-btn {
    aspect-ratio: 1 / 1;
    border-radius: 50px;
    padding: 12px;
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }
  h3 {
    font-size: 1.5rem;
    margin: 0 0 1rem;
  }
  .btn {
    font-size: 1rem;
    padding: 0.7rem 2rem;
  }
  .author-links {
    display: flex;
    align-items: center;
    a {
      background: $brandred;
      &:hover {
        background: $brandorange;
      }
      margin-bottom: 0;
      margin-right: 1rem;
      border-radius: 50px;
    }
  }
}
