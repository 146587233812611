.how-we-do-it {
  padding: 6rem 0;
  &-title {
    text-align: center;
    padding-bottom: 2rem;
  }
  // &-single {
  //   background-color: $brand-main;
  //   color: white;
  //   min-height: 450px;
  //   padding: 2rem;
  //   text-shadow: 2px 2px 6px rgba(79, 79, 79, 0.89);
  //   &-title {
  //     font-size: 2rem;
  //     margin-top: 1.3rem;
  //   }
  // }
}
.our-clients {
  background: $brand-main;
  padding: 6rem 0;
  &-title {
    text-align: center;
    padding-bottom: 2rem;
    color: white;
  }
  &-single {
    display: flex;
    align-items: center;
    @include mobile() {
      &.quart {
        width: calc(50% - 18px);
      }
    }
    img {
      max-height: 100px;
      max-width: 150px;
      margin: 2rem auto;
    }
  }
}
.what-were-saying {
  padding: 6rem 0;
  &-title {
    text-align: center;
    padding-bottom: 2rem;
  }
  &-single {
    min-height: 450px;
    background: $brand-secondary;
    border-radius: 0 0 6px 6px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    &-title {
      margin-top: auto;
      color: white;
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.sub-hero {
  padding: 6rem 0;
  p {
    font-size: 2rem;
    @media screen and (max-width: 1440px) {
      font-size: 1.5rem;
      width: 100% !important;
    }
  }
  .hero-wordlist {
    display: inline-block;
  }
}
