.animated.delay-100ms {
  animation-delay: 0.1s;
}
.animated.delay-200ms {
  animation-delay: 0.2s;
}
.animated.delay-300ms {
  animation-delay: 0.3s;
}

.animated.delay-400ms {
  animation-delay: 0.4s;
}
.animated.delay-500ms {
  animation-delay: 0.5s;
}

.animated.delay-600ms {
  animation-delay: 0.6s;
}
.animated.delay-700ms {
  animation-delay: 0.7s;
}
.animated.delay-800ms {
  animation-delay: 0.8s;
}

.animated.delay-900ms {
  animation-delay: 0.9s;
}
.animated.delay-1000ms {
  animation-delay: 1s;
}

.animated.delay-1100ms {
  animation-delay: 1.1s;
}
.animated.delay-1200ms {
  animation-delay: 1.2s;
}
.animated.delay-1300ms {
  animation-delay: 1.3s;
}
.animated.delay-1400ms {
  animation-delay: 1.4s;
}
.animated.delay-1500ms {
  animation-delay: 1.5s;
}
.animated.delay-1600ms {
  animation-delay: 1.6s;
}

.animated.delay-1700ms {
  animation-delay: 1.7s;
}

.animated.delay-1800ms {
  animation-delay: 1.8s;
}

.animated.delay-1900ms {
  animation-delay: 1.9s;
}

.animated.delay-2000ms {
  animation-delay: 2s;
}

.animated.delay-2100ms {
  animation-delay: 2.1s;
}
.animated.delay-2200ms {
  animation-delay: 2.2s;
}
