.technology-under-hero {
  text-align: center;
  padding: 6rem 0;
  background: $brandteal;
  h3 {
    max-width: 850px;
    margin: auto;
    padding: 1rem;
    font-weight: $contentFontsWeight;
    font-size: 2rem;
  }
}
