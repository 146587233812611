// Archive
.simpul-hero.services {
  .hero-title {
    max-width: 700px;
  }
}
.services-list-page {
  padding: 2rem 0 4rem;
  &-title {
    // text-align: center;
    // margin-bottom: 2rem !important;
  }

  .pagination {
    li {
      &.current a {
        color: $offWhite;
      }
    }
    a {
      color: $offBlack;
    }
  }
}
.half-block--item {
  background-color: $brand-main;
  background-size: cover;
  background-position: center;
  color: white;
  min-height: 450px;
  padding: 2rem;
  text-shadow: 2px 2px 6px rgba(79, 79, 79, 0.89);
  display: flex;
  @include lessThanTablet() {
    min-height: 350px;
  }
  &-title {
    align-self: flex-end;
    font-size: 2rem;
    margin-top: auto;
    font-weight: $contentFontsWeight;
  }
  &.upper-third-block {
    .half-block--item-title {
      align-self: flex-start;
      margin-bottom: auto;
      margin-top: 0;
    }
  }
}

// Single
.services-single {
  background-color: $brandblue;
  color: white;
  .wp-block-column img {
    max-width: 300px !important;
    @include lessThanTablet() {
      max-width: 60% !important;
      margin: 1rem auto;
    }
  }
  @include lessThanDesktop() {
    h3 {
      font-size: 1.6rem;
    }
    .wp-block-columns {
      margin-bottom: 50px;
    }
  }
  @include lessThanTablet() {
    h3 {
      padding-left: 1rem;
    }
  }

  .content {
    padding-top: 1em;
  }
}
.single-services .wp-block-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.single-services .wp-block-column * {
  width: 100%;
}
.single-services .wp-block-column h3 {
  margin-bottom: 0;
}
.single-services .wp-block-columns {
  margin-bottom: 3rem;
}
