/*!
Theme Name: demandmore
Theme URI: https://demandmore.com/
Author: demandmore / guwii
Author URI: https://demandmore.com
Description: demandmore
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: demandmore
*/
@charset "UTF-8";
// "Simpul" SASS / HTML & PHP website starting block that suits the majority of basic websites being built from scratch
// https://github.com/mrwigster/simpul/
// By @MrWigster

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

// Useful variables, brand colours, fonts, breaakpoint widths
@import "vars";

// The normalise styles by Bootstrap to normalize consistent styles across all browsers and set up some nice baseline standards
@import "simpul/norm";

// Media query setup
@import "simpul/mediaq";

// Basic layout and framework structure
@import "simpul/framework";

// Basic polyfill to fall grid layout down to grid
@import "simpul/polyfill";

// Little elements, such as buttons, icons etc.
@import "simpul/bits";

// Header of site
@import "simpul/header";

// Footer of site
@import "simpul/footer";

// All baseline typography settings
@import "simpul/typography";

@import "util";
@import "animation";
@import "landing-page";

@import "cursor";
// Styles for the home/front page
@import "home";

// Basic blog styles
@import "blog";

// Work/Project styles
@import "work";

// contact Page
@import "contact";

// services Page
@import "services";

@import "about";
@import "careers";
@import "technology";

@import "case-study";
@import "genericpage";
@import "select2";

// #wpadminbar {
//   // display: none;
// }
// html[lang="en-US"] {
//   // margin-top: 0 !important;
// }

// overides:
.contact-page .title-under-hero,
.landing-page .title-under-hero {
  padding: 3rem 0;
}

.our-clients-title {
  @include lessThanTablet() {
    order: 0;
  }
}
.our-clients-single {
  order: 10;
  @include lessThanTablet() {
    &:nth-child(4) {
      order: 1;
    }
    &:nth-child(7) {
      order: 2;
    }
    &:nth-child(8) {
      order: 3;
    }
    &:nth-child(3) {
      order: 3;
    }
  }
}

.generic-single img {
  padding: 18px;
  text-align: center;
  color: #000;
  border: 1px solid #d6dade;
  border-radius: 4px;
}

b,
strong {
  font-weight: bold;
}
.content ul {
  padding-left: 2.5rem;
}
table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid $brandblue;
  /* 	border-radius: 3px; */
}
table {
  border-collapse: inherit;
  padding: 0.2rem;
}
td {
  border-collapse: collapse;
}
th,
td {
  padding: 0.66rem;
}

.pagination {
  margin: 1em auto 3em;
}
.blog-category {
  padding: 0 1rem;
  font-weight: 100;
  font-size: 0.9rem;
}

.what-we-do .single-what-we-do-title {
  margin-bottom: 0.75rem;
  font-size: 2.3rem;
  font-weight: normal;
  strong {
    font-weight: bold;
  }
}
.landing-page .title-under-hero h1 {
  font-size: 3rem;
  padding: 1rem 0;
}
.landing-section2 {
  font-size: 1.66rem;
}
.case-studies-trio h3 {
  font-size: 2rem;
}
.single-services .site-main {
  padding-top: 3rem;
}
@media (max-width: 767px) {
  .services-single .wp-block-column img {
    margin-bottom: 2rem;
  }
}
.case-study-trio-single-title {
  color: white;
}
.case-study-trio-single .read-more-rarr {
  color: white;
}
.blog-category {
  display: block;
}
.blog-category ul {
  padding-left: 0;
  font-size: 1.1rem;
}
textarea,
input {
  max-width: 100% !important;
}
@media (max-width: 767px) {
  .careers-blocks {
    padding-top: 3rem;
  }
}
.wp-block-table {
  max-width: 768px;
  margin: 1em auto;
  font-weight: 200;
}
.landing-form-cta-embed {
  border: 1px solid white;
  padding: 1rem;
  border-radius: 2px;
}
.wpforms-field-label {
  padding: 0;
}
.wpforms-field-row {
  max-width: 100% !important;
}
select.wpforms-field-medium {
  margin-top: 1rem;
  max-width: 100% !important;
  display: block;
  margin: 1rem auto 0;
}
.wpforms-container {
  margin-bottom: 0 !important;
}
label.wpforms-field-sublabel {
  padding: 0;
  margin-top: 0.5rem;
}
.wpforms-submit {
  width: 100%;
  margin-bottom: 0.3rem;
}
.landing-form-cta-embed h6 {
  margin: 0.33rem 0 0.7rem;
  text-align: center;
}
.landing-section1 .container {
  align-items: flex-start;
}
@media screen and (max-width: 1024px) {
  .landing-form-cta-embed {
    width: 100% !important;
  }
}
.landing-section2 h4 {
  margin: 1rem 0 0;
}

.what-were-saying-single {
  background-size: cover;
}

.content ol {
  max-width: 768px;
  margin: 1em auto;
  font-weight: 200;
  padding-left: 1rem;
}
.sharedaddy.sd-sharing-enabled {
  text-align: center;
  max-width: 768px;
  margin: auto !important;
  @include lessThanTablet() {
    padding-left: 1em;
  }
}
.sd-content {
  margin: auto !important;
}
hr {
  width: 100%;
  background: #dedede;
  border: none;
  height: 1px;
  max-width: 1024px;
}

.related-posts .what-were-saying {
  padding-top: 3rem;
}

@media screen and (max-width: 768px) {
  .simpul-hero .hero-content .hero-title {
    font-size: 2.6rem;
  }
}
.blog-archive h1 {
  margin-bottom: 2.5rem;
}
.categories-list {
  text-align: center;
  margin-bottom: 2rem !important;
}
.categories-list span {
  font-weight: bold;
  padding: 0 20px;
}
@media screen and (max-width: 1024px) {
  .categories-list span {
    display: inline-block;
    margin-bottom: 1rem;
    width: 48%;
    margin-right: auto;
  }
  .blog-archive h1 {
    margin-bottom: 1rem;
  }
  .categories-list {
    margin-bottom: 0rem !important;
    font-size: 1rem;
  }
}
.what-were-saying a {
  text-decoration: none;
}
@media only screen and (min-width: 641px) {
  .gform_wrapper .top_label input.medium,
  .gform_wrapper .top_label select.medium {
    width: 100% !important;
  }
}

.yarpp-related {
  a {
    font-weight: normal !important;
  }
}
.yarpp-related-shortcode h3 {
  font-size: 2.25rem !important;
}
.sharedaddy.sd-sharing-enabled {
  text-align: left;
  margin-left: 2.3rem;
}

.blog-archive .blog--item-featuredimg {
  border-radius: 4px 4px 0 0;
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .landing-section1 {
    padding: 6rem 3rem;
  }
}
@media screen and (max-width: 768px) {
  .simpul-hero.home {
    background-position: 19%;
  }

  .what-we-do .single-what-we-do-title {
    font-size: 1.7rem;
  }
}
.contact-page .address a {
  color: white !important;
}
.work-list-page {
  padding-top: 3rem;
}
.half-block--item {
  position: relative;
}
.casestudycategory {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 1rem 0;
  border-radius: 13px;
  padding: 0.5rem 1rem;
  color: white;
}
.relative-wrap {
  position: relative;
}
.address-info p {
  margin-top: 0;
}

.careers-blocks {
  padding: 3rem 0 4rem;
}
.generic-single hr {
  max-width: 768px;
}

.landing-section2 img {
  max-width: 220px;
  vertical-align: middle;
  margin: 2rem;
}

.full.content {
  padding-top: 3rem;
}

.our-clients-single a {
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
}
.how-we-do-it-single {
  background-position: 50%;
}
body.home .simpul-hero {
  // background-color: white;

  background-position: right;
  background-size: contain;
  padding: 8rem;
  min-height: 0;
  .hero-title {
    line-height: 1.5;
  }
}
@media screen and (min-width: 1200px) {
  body.home .simpul-hero {
    padding: 20rem 8rem 4rem;
  }
}
@media screen and (min-width: 1400px) {
  body.home .simpul-hero {
    padding: 25rem 8rem 4rem;
  }
}
.full-side-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-image: url(https://demandmore.co.uk/wp-content/uploads/2023/05/demand-more-paid-more-data-driven.jpg);
  background-position: center;
  background-size: cover;
  @include lessThanDesktop() {
    width: 50%;
    display: none;
  }
}

body.home .simpul-hero .half:nth-child(2) {
  margin-left: auto !important;
}
body.home .simpul-hero img {
  height: 100%;
  object-fit: cover;
  display: block;
}
body.home .simpul-hero .flex-wrap {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

body.home .hero-title {
  font-size: 2rem !important;
  background-position: left;
}
@media screen and (max-width: 1024px) {
  body.home .simpul-hero .full-side-bg {
    display: none;
  }
  body.home .simpul-hero {
    padding: 3rem 0;
    min-height: 65vh;
    align-items: center;
    align-content: center;
    display: flex;
  }
}
@media screen and (max-width: 768px) {
  body.home .hero-title {
    font-size: 1.5rem !important;
  }

  body.home .simpul-hero {
    background-image: none;
  }
}
.wp-block-table {
  margin: auto;
}

// .gutentoc {
//   position: sticky;
//   top: 50px;
//   width: 100%;
//   background: white;
// }
// .wp-block-uagb-table-of-contents ul.uagb-toc__list,
// .wp-block-uagb-table-of-contents ol.uagb-toc__list {
//   padding: 0;
//   margin-left: 0;
// }

footer a:hover {
  color: white;
}

.half-block--item:before {
  content: "";
  // background: linear-gradient(0deg, #080e21, transparent);
  background: linear-gradient(
    0deg,
    #080e21 0%,
    rgb(8 14 33 / 0%) 45%,
    #080e2100 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}
.half-block--item.hover-scale:hover {
  transform: scale(1.035);
}
.half-block--item.no-gradient:before {
  display: none;
}
.half-block--item * {
  position: relative;
  z-index: 2;
}
a.half-block--item {
  transition: all 0.2s ease;
  overflow: hidden;
}
a.half-block--item:hover {
  text-decoration: none;
  color: white;
  background-size: 200%;
}
a.half-block--item:hover::after {
  transform: scale(1.1);
}
a.half-block--item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: inherit;
  background-size: cover;
  transform-origin: center;
  transition: transform 0.4s ease-in-out;
}
.bubble-el {
  border-radius: 20px;
  color: $brandblue;
  background: white;
  display: inline-block;
  margin: 0 10px 20px;
  padding: 0 !important;
  transition: all 0.2s ease;
  z-index: 10;
}
.bubble-el a {
  padding: 10px 20px;
}
a.bubble-el {
  padding: 5px 20px !important;
  font-size: 1rem;
}
.bubble-el:hover {
  text-decoration: none;
  transform: scale(1.05);
  color: white;
  background: $brandblue;
}
.bubble-el a:hover {
  text-decoration: none;
}
.bubble-el--blue {
  background: $brandblue;
  color: white;
}
.bubble-el--blue a {
  color: white;
}
.sd-content {
}
a.sd-button {
  background: $brandblue !important;
}
a.sd-button:hover {
  background: $brandorange !important;
  opacity: 1 !important;
}
.btn:hover {
  text-decoration: none;
}
/* // tech page hide banner title*/
.page-id-11 .banner-title {
  display: none;
}
.page-id-11 .banner a {
  margin-top: 2rem;
}
/* services archive page */
.post-type-archive-services .half-block--item:before {
  display: none;
}
.post-type-archive-services .half-block--item-title {
  margin-bottom: auto;
  margin-top: 0;
}
.site-header {
  position: sticky;
  top: 0;
  transition: all 0.2s ease;
}
.archive .site-footer {
  margin-top: 4rem;
}
.threequart.content .animated {
  -webkit-animation-fill-mode: initial;
  animation-fill-mode: initial;
}

.content .modern-footnotes-list {
  padding-left: 0;
}
.title-under-hero h3 {
  max-width: 100%;
  text-align: left;
  padding: 1rem 0;
}
.contact-page .contactform h3 {
  padding: 0;
}
@media screen and (min-width: 1024px) {
  .contact-page .address {
    padding-left: 2.5rem;
  }
}
.site-header .logo svg g {
  fill: $brandorange;
}
.site-header .logo:hover svg g {
  fill: $brandpink;
}
.orangeheader {
  .simpul-hero,
  header.site-header,
  .title-under-hero {
    background-color: $brandorange;
  }
  header.site-header .menu > li:last-of-type a {
    color: white;
  }
  header.site-header .menu > li:last-of-type a:hover {
    background-color: $brandblue;
  }
  .site-header .logo {
    svg g {
      fill: $brandblue;
    }
    &:hover {
      svg g {
        fill: $brandpink;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    header.site-header .menu a {
      color: $brandblue;
    }
  }
  header.site-header .menu a:hover {
    color: $brandpink;
  }
}
.redheader {
  .simpul-hero,
  header.site-header,
  .title-under-hero {
    background-color: $brandpink;
  }
  header.site-header .menu > li:last-of-type a {
    color: white;
    background-color: $brandorange;
    &:hover {
      background-color: $brandblue;
    }
  }
  header.site-header .menu a {
    &:hover {
      color: $brandorange;
      &:before {
        background-color: $brandorange;
      }
    }
  }

  .site-header .logo {
    svg g {
      fill: $brandblue;
    }
    &:hover {
      svg g {
        fill: $brandorange;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    header.site-header .menu a {
      color: white;
    }
  }
}

// WP Theme logic:
.has-red-background-color {
  background-color: $brandred;
}
.has-blue-background-color {
  background-color: $brandblue;
}
.has-teal-background-color {
  background-color: $brandteal;
}
.has-orange-background-color {
  background-color: $brandorange;
}
.has-red-color {
  color: $brandred;
}
.has-blue-color {
  color: $brandblue;
}
.has-teal-color {
  color: $brandteal;
}
.has-orange-color {
  color: $brandorange;
}
body {
  --wp--preset--font-size--medium: 2rem !important;
  --wp--preset--font-size--large: 3rem !important;
}
// .has-medium-font-size {
//   font-size: 2rem;
// }
.content {
  .wp-block-column,
  .wp-block-group__inner-container {
    margin: auto;
    max-width: $containedWidth !important;
    @include lessThanDesktop() {
      padding: 0 $flexGap/2;
    }
    @include wide() {
      max-width: 1440px !important;
    }

    p,
    ul,
    ol,
    img,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 1rem 0;
    }
  }
}

.dm-vid-player {
  position: relative;
  max-height: 95vh;
  aspect-ratio: 16 / 9;
  padding: 0;
  margin: auto;
  @include lessThanDesktop() {
    width: 100% !important;
    padding: 0 !important;
    height: auto !important;
  }
}

.newservicepage.services-single .content,
.newservicepage.single-services .site-main {
  padding-top: 0;
}
.newservicepage .case-studies-trio {
  display: none;
}

.newservicepage main > .flex-wrap > .container {
  width: 100%;
  max-width: 100%;
}
.schema-faq-answer {
  /* 	max-width: 1440px!important; */
  margin: 1rem 0 !important;
}
.newservicepage blockquote {
  border: none;
  padding: 0;
  margin: 0;
}
.newservicepage cite {
  font-style: normal;
}
.newservicepage .wp-block-quote > p {
  font-size: 2rem;
  line-height: 1.3;
}
body {
  .newservicepage div.services-single .wp-block-column img {
    max-width: 100% !important;
  }
  .wp-block-yoast-faq-block .schema-faq-section {
    background: none !important;
    color: inherit !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .wp-block-yoast-faq-block .schema-faq-question {
    padding: 0 !important;
    font-size: 1.5rem !important;
    border-top: 2px solid white !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .wp-block-yoast-faq-block .schema-faq-section a {
    color: inherit !important;
  }
  .schema-faq-section:last-of-type {
    border-bottom: 2px solid white;
  }
  .wp-block-yoast-faq-block .schema-faq-question:after {
    color: inherit;
    content: "▼";
    transition: all 0.3s ease;
  }
  .wp-block-yoast-faq-block .schema-faq-question.faq-q-open {
    border-bottom: 0;
  }
  .wp-block-yoast-faq-block .schema-faq-question.faq-q-open:after {
    transform: rotate(180deg);
    content: "▼";
  }
  .wp-block-yoast-faq-block p.schema-faq-answer {
    background: none;
    padding: 0 0 1rem;
    border-bottom: 0;
    font-size: 1.2rem;
    margin: 0 !important;
  }
}
.flex-direction-row .wp-block-column {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flex-direction-row .wp-block-image {
  width: auto;
}
.services-custom-gallery .wp-block-image {
  margin: auto !important;
  padding: 0 20px;
}
@media screen and (min-width: 1400px) {
  .single-services .sub-hero p {
    max-width: 80%;
  }
}
.case-studies-trio.sc_case_studies {
  display: block;
}
.sc_case_studies .case-studies-inc {
  margin-bottom: 0;
}

.services-single .content {
  padding-top: 0;
}
.newservicepage h1 {
  font-size: 2.5rem;
}
.newservicepage .content .wp-block-column {
  margin: inherit;
}
.case-studies-inc a,
.sc_blog_grid a {
  text-decoration: none;
}
.newservicepage.single-services .wp-block-columns {
  margin-bottom: 0;
}
.sc_blog_grid .half-block--item {
  min-height: 320px;
}
.newservicepage .half-block--item {
  text-decoration: none;
}
.newservicepage .are-vertically-aligned-top {
  align-items: flex-start !important;
}
.heading-underline {
  border-bottom: 2px solid;
  display: inline-block;
  width: auto !important;
  align-self: flex-start;
  padding-bottom: 5px;
  margin-bottom: 0.5rem;
}
.heading-underline ~ p {
  margin-top: 0 !important;
}
@media screen and (min-width: 1440px) {
  .newservicepage .hero-content {
    max-width: 40%;
  }
}

/* /// */
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}

.menu-item-has-children {
  position: relative;
}
.menu-item-has-children:hover .sub-menu {
  display: block;
}
.sub-menu {
  position: absolute;
  left: 0;
  margin: 0;
  background: #274190;
  padding: 20px 20px 10px;
  min-width: 175px;
}
header.site-header .sub-menu a {
  display: inline-block;
  padding: 0 !important;
  margin-bottom: 15px;
  color: white !important;
}
header.site-header .sub-menu a:before {
  left: 0;
}
header.site-header .sub-menu a:hover:before {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .sub-menu {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .sub-menu {
    position: relative;
    display: none;
  }
}
.menu-item-has-children > a::after {
  content: "▼";
  transition: all 0.3s ease;
  margin-left: 10px;
  font-size: 0.75rem;
  color: inherit;
}
.menu-item-has-children:hover > a::after {
  transform: rotate(180deg);
  display: inline-block;
}

header.site-header .menu .sub-menu li:last-of-type a {
  padding: 0;
  background: none;
}

@media screen and (min-width: 1024px) {
  .landing-section1 {
    padding: 4rem 3rem;
  }
}
.modern-footnotes-list li > span {
  color: #274190;
}

.our-clients-single img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}
.wp-embed-aspect-16-9 iframe {
  aspect-ratio: 16 / 9;
}
.constrain-content-width {
  max-width: 768px;
}

iframe[src*="slideshare.net"] {
  min-height: 313px;
}
iframe[data-src*="slideshare.net"] {
  min-height: 313px;
}
.newservicepage .half-block--item {
  height: 100%;
}

.learn-more-arrow {
  display: inline-block;
  font-size: 1.4rem;
  margin-left: 6px;
  margin-right: -13px;
  transform: rotate(-90deg);
  position: absolute;
  right: 3rem;
  bottom: 2.4rem;
  transition: all 0.2s ease;
  @media screen and (min-width: 768px) and (max-width: 1040px) {
    display: none;
  }
  .half-block--item:hover & {
    transform: rotate(-90deg) translateX(5px) scale(1.5);
  }
}
