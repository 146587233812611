@media (hover: hover) {
  html.cursor,
  html.cursor *:hover {
    cursor: none;
  }
}

#Cursor {
  width: 20px;
  height: 20px;
  position: relative;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform-origin: center;
  visibility: visible;
  z-index: 9999;
}

.cursor-el {
  position: absolute;
  background: #0fc8da;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  pointer-events: none;
  transform: scale(var(--scale));
  transition: all 0.05s ease;
}
@include lessThanDesktop() {
  .cursor-el {
    display: none;
  }
}
