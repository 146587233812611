.about-page-first-content {
  max-width: 1024px;
  // margin: auto;
  padding: 3rem 9px;
  // text-align: center;
  font-size: 1.3rem;
}
// .about-page-second-content {
//   max-width: 750px;
//   margin: auto;
//   padding: 3rem 1rem;
//   text-align: center;
// }
.about-page-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.single-what-we-do {
  padding: 2rem 0;
  border-bottom: 1px solid white;
}
.our-team-members {
  padding: 3rem 0;
  img {
    width: 100%;
  }
}
.team-member-name {
  margin-top: 1rem;
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
}
.team-member-description {
  font-size: 1rem;
  max-width: 75%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.3;
}
.our-values-title {
  // text-align: center;
  font-size: 2rem;
}

.value-description {
  max-width: 420px;
  line-height: 1.4;
}
