.bg-brandblue {
  background: $brandblue;
  color: white;
}
.bg-brandpink {
  background: $brandpink;
  color: white;
}
.bg-brandorange {
  background: $brandorange;
}
button,
.btn,
input[type="submit"],
.button {
  border: none;
  border-radius: 3px;
  background: $brandpink;
  color: white;
  display: inline-block;
  padding: 0.75rem 4rem;
  font-weight: 600;
  font-size: 1.35rem;
  max-width: 100%;
}
.btn-orange {
  background: $brandorange;
  &:hover {
    background: $brandblue;
    color: white;
  }
}
// .btn-red {
//   background: $brandred;
//   &:hover {
//     background: $brandblue;
//     color: white;
//   }
// }

label {
  padding: 0 0.5rem;
}
textarea,
input {
  border-width: 1px;
  border-color: transparent transparent white transparent;
  background: transparent;
  border-radius: 0;
  padding: 1rem 0.5rem;
  color: white;
  &:hover,
  &:active,
  &:focus {
    border-color: white;
  }
}

.section-padding {
  padding: 3rem 0;
}
