.select2-container--default .select2-selection--single {
  border: none !important;
  background: none !important;
}
.select2-selection__arrow b {
  display: none !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: $brand-main !important;
  border-bottom: 2px solid $brand-main;
  padding-bottom: 1rem;
}

.select2-container--open .select2-dropdown {
  border: none;
  border-radius: 0;
  background: $brand-main;
  color: white;
}

.select2-container--default .select2-results__option--selected {
  background: $brand-main !important;
  color: white !important;
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  color: $brand-main !important;
  background: white !important;
}
