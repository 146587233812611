// Archive
.simpul-hero.work {
  // background-image: url(img/our-work.jpg);
  min-height: 20vh;
  padding: 3rem 0 5rem;
}
.work-list-page {
  padding: 4rem 0;
  &-title {
    // text-align: center;
    // margin-bottom: 3rem !important;
  }

  .pagination {
    li {
      &.current a {
        color: $offWhite;
      }
    }
    a {
      color: $offBlack;
    }
  }
}

.simpul-hero.case-study {
  min-height: auto;
  .hero-title {
    font-weight: $headingFontsWeight;
    font-size: 3.5rem;
  }
  .hero-subtitle {
    color: $brandblue;
  }
}
@media screen and (min-width: 1200px) {
  .case-study.simpul-hero {
    padding: 20rem 8rem 4rem;
  }
}
@media screen and (min-width: 1400px) {
  .case-study.simpul-hero {
    padding: 25rem 8rem 4rem;
  }
}
.case-study-single {
  // padding-top: 4rem;
}

.what-the-client-said {
  background: $brandpink;
  color: white;
  padding: 8rem 0;
  .what-the-client-said-content {
    font-size: 2rem;
    line-height: 1.3;
  }
  h3 {
    margin-top: 0;
    font-size: 2.3rem;
  }
  h3,
  .what-the-client-said-content,
  .what-the-client-said-author {
    max-width: 800px;
    padding: 0 1rem;
  }
}
.the-results-case-study {
  background: $brandblue;
  text-align: left;
  padding: 6rem 0;
  h3 {
    // margin-top: 0;
    font-size: 2.3rem;
    color: White;
    // font-weight: 400;
  }
  .third {
    padding: 2rem 0rem;
  }
}
.the-results-large-stat {
  font-size: 4.5rem;
  // font-weight: $headingFontsWeight;
  font-weight: 400;
  border-bottom: 2px solid white;
  padding: 0 0 1rem;
  max-width: 230px;
  text-align: left;
  margin: 0 0 1rem;
  color: white;
}

.the-results-describer {
  font-weight: $headingFontsWeight;
  margin-bottom: 2rem;
  padding: 0;
  color: white;
}
