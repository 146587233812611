$offWhite: #f4f4f4;
$offBlack: #151515;

$brandblue: #274190;
$brandpink: #d84d78;
$brandred: $brandpink;
$brandteal: #0fc8da;
$brandorange: #ffa587;

$brand-main: $brandblue;
$brand-main-complement: $brandpink;

$brand-secondary: $brandteal;
$brand-secondary-complement: $brandorange;

// Typography basics
$contentFonts: "Raleway", sans-serif;
$contentFontsWeight: 400;
$headingFonts: "Raleway", sans-serif;
$headingFontsWeight: 700;
// Applied to body, sets the basis for all the em/rems on the site
$baseFontSize: 18px;

// Breakpoint we consider largest size for a mobile
$mobile-width: 475px;
// Minimum breakpoint to consider a tablet
$tablet-width: 768px;
// Minimum breakpoint to consider a desktop
$desktop-width: 1024px;
// For wide screen
$wide-width: 1480px;
// Sets the maximum width a container will go to, we don't want really long lines of text the complete width of a widescreen browser
$containedWidth: 1024px;

// Basic link controls
$link-color: $brand-main;
$link-hover-color: $brand-secondary;
$link-decoration: none;
$link-hover-decoration: underline;

// Flex Layout:
// Gap/margin between our flex items
$flexGap: 18px;

// Grid Layout:
// Control the number of columns our grid has, it truly only really works with 12
$gridColumns: 12;
$gridGap: $baseFontSize;
