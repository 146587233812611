.landing-page {
  .title-under-hero {
    h1 {
      width: 1080px;
      max-width: 100%;
      margin: 9px;
    }
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
  }
}

.landing-section1,
.landing-section2 {
  padding: 6rem 0;
}
.landing-section1 {
  font-size: 1.3rem;
}
.landing-section2 {
  font-size: 3rem;
  @include lessThanDesktop() {
    font-size: 1.6rem;
  }
  p {
    text-align: center;
  }
  img {
    max-width: 120px;
    vertical-align: middle;
    margin: 1rem;
  }
  h4 {
    text-align: center;
    font-size: 3rem;
  }
}
.landing-form-cta-embed {
  background: white;
  color: $brandblue;
}
.landing-content-1 {
  padding-right: 2rem;
}
.landing-form-cta-embed input {
  border-bottom-color: $brandblue;
  color: $brandblue;
}
.landing-form-cta-embed input[type="submit"] {
  color: white;
}

.landing-section1 .container {
  max-width: 1440px;
  justify-content: space-between;
}
@media screen and (max-width: 1480px) {
  .landing-section1 .container {
    max-width: 1024px;
  }
}
@media screen and (min-width: 768px) {
  .flex-wrap .landing-content-1 {
    margin-left: 0;
  }
  .flex-wrap .landing-content-2 {
    margin-right: 0;
  }
}

.flex-wrap .landing-content-1 {
  margin: 9px;
}
.page-id-961 .landing-section2 {
  font-size: 1.25rem;
}

.page-template-template-landing-page .title-under-hero h1 {
  text-align: left;
  // max-width: 1440px;
  width: 100%;
  color: $brandblue;
}

.page-id-1119 .site-header,
.page-id-1102 .site-header {
  border-bottom: 1px solid #1b3480;
  box-shadow: 0 0 10px 10px #223a824f;
  z-index: 100;
  position: relative;
}

.page-id-1119 .landing-form-cta-embed img {
  max-width: 150px;
}

.page-id-1119 .landing-form-cta-embed {
  flex-direction: column;
}

.page-id-1119 .title-under-hero,
.page-id-1102 .title-under-hero {
  display: none;
}

.page-id-1119 .landing-content-1,
.page-id-1102 .landing-content-1 {
  background: white;
  padding: 2rem;
  color: #274190;
}

.page-id-1119 .landing-content-1 h6,
.page-id-1102 .landing-content-1 h6 {
  margin-top: 0;
}

.page-id-1119 input,
.page-id-1102 input {
  border-bottom: 1px solid #274190;
  color: black;
}

.page-id-1119 .landing-form-cta-embed p,
.page-id-1102 .landing-form-cta-embed p {
  font-size: 2rem;
  width: 100%;
}

.page-id-1119 .landing-form-cta-embed,
.page-id-1102 .landing-form-cta-embed {
  background: none;
  color: white;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.page-id-1119 input[type=" submit"],
.page-id-1102 input[type="submit"] {
  color: white;
  margin-left: auto;
}
